<template>
  <a-tabs type="card" @change="callback">
    <a-tab-pane key="userList" tab="用户列表"><user-list /></a-tab-pane>
  </a-tabs>
</template>

<script>
import UserList from "./user-list";

export default {
  components: {
    UserList,
  },
  methods: {
    callback() {},
  },
};
</script>