<template>
  <div v-if="authRelates.length > 0">
    <div v-for="authRelate in authRelates" :key="authRelate.orgId">
      <div
        v-for="item in authRelate.groups"
        :key="authRelate.orgId + '-' + item.groupId"
      >
        <div>{{ authRelate.orgName }} - {{ item.groupName }}</div>
        <a-select
          mode="multiple"
          :value="item.roles"
          @select="(value) => handleSelect(value, item.roles)"
          @deselect="(value) => handleDelete(value, item.roles)"
          style="width: 100%"
        >
          <a-select-option
            v-for="role in roleList"
            :value="role.roleId"
            :key="role.roleName"
          >
            {{ role.roleName }}
          </a-select-option>
        </a-select>
      </div>
    </div>
  </div>
  <div v-else>请先给用户配置机构和用户组</div>
</template>

<script>
import { updateUserRole, getAllRoles } from "@/api/auth";

export default {
  name: "TaskForm",
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      authRelates: [],
      roleList: [],
      selectItems: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.authRelates = [];
      this.record.authRelate &&
        this.record.authRelate.forEach((item) => {
          let authRelateItem = {
            orgId: item.org.id,
            orgName: item.org.name,
            groups: [],
          };
          item.roleGroups.forEach((i) => {
            authRelateItem.groups.push({
              groupId: i.group.id,
              groupName: i.group.name,
              roles: i.roles.map((r) => r.id),
            });
          });
          this.authRelates.push(authRelateItem);
        });
      getAllRoles().then((res) => {
        if (res.code === 0) {
          this.roleList = res.data;
        }
      });
    },
    handleSelect(value, roles) {
      roles.push(value);
    },
    handleDelete(value, roles) {
      let index = roles.indexOf(value);
      if (index > -1) {
        roles.splice(index, 1);
      }
    },
    onOk() {
      return new Promise((resolve) => {
        let formData = {
          userId: this.record.userId,
          bind: [],
        };
        this.authRelates.forEach((item) => {
          item.groups.forEach((i) => {
            formData.bind.push({
              groupId: i.groupId,
              roleIds: i.roles,
            });
          });
        });
        updateUserRole(formData).then(() => {
          this.$message.success("更新成功");
          resolve(true);
        });
      });
    },
    onCancel() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
  },
};
</script>

<style scoped>
/* tile uploaded pictures */
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
</style>
