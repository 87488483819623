var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"用户名","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'name',
        { initialValue: _vm.userInfo.name },
        { rules: [{ required: true, message: '请输入用户名!' }] } ]),expression:"[\n        'name',\n        { initialValue: userInfo.name },\n        { rules: [{ required: true, message: '请输入用户名!' }] },\n      ]"}],attrs:{"placeholder":"请输入用户名"}})],1),_c('a-form-item',{attrs:{"label":"密码","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置密码")])],1),_c('a-form-item',{attrs:{"label":"姓名","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'realName',
        { initialValue: _vm.userInfo.realName },
        { rules: [{ required: true, message: '请输入真实姓名!' }] } ]),expression:"[\n        'realName',\n        { initialValue: userInfo.realName },\n        { rules: [{ required: true, message: '请输入真实姓名!' }] },\n      ]"}],attrs:{"placeholder":"请输入真实姓名"}})],1),_c('a-form-item',{attrs:{"label":"所在部门","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'department',
        { initialValue: _vm.userInfo.department },
        { rules: [{ required: true, message: '请输入所在部门!' }] } ]),expression:"[\n        'department',\n        { initialValue: userInfo.department },\n        { rules: [{ required: true, message: '请输入所在部门!' }] },\n      ]"}],attrs:{"placeholder":"请输入所在部门"}})],1),_c('a-form-item',{attrs:{"label":"职位","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'position',
        { initialValue: _vm.userInfo.title },
        { rules: [{ required: true, message: '请输入职位!' }] } ]),expression:"[\n        'position',\n        { initialValue: userInfo.title },\n        { rules: [{ required: true, message: '请输入职位!' }] },\n      ]"}],attrs:{"placeholder":"请输入职位"}})],1),_c('a-form-item',{attrs:{"label":"联系电话","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'phone',
        { initialValue: _vm.userInfo.phone },
        {
          rules: [
            {
              required: true,
              message: '请输入联系电话!',
            } ],
        } ]),expression:"[\n        'phone',\n        { initialValue: userInfo.phone },\n        {\n          rules: [\n            {\n              required: true,\n              message: '请输入联系电话!',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"请输入联系电话"}})],1),_c('a-form-item',{attrs:{"label":"电子邮件","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'email',
        { initialValue: _vm.userInfo.email },
        {
          rules: [
            {
              required: true,
              message: '请输入电子邮件!',
            } ],
        } ]),expression:"[\n        'email',\n        { initialValue: userInfo.email },\n        {\n          rules: [\n            {\n              required: true,\n              message: '请输入电子邮件!',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"请输入电子邮件"}})],1),_c('a-form-item',{attrs:{"label":"属性","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['userType', { initialValue: _vm.userInfo.userType }]),expression:"['userType', { initialValue: userInfo.userType }]"}],staticStyle:{"width":"120px"}},[_c('a-select-option',{attrs:{"value":"GENERAL"}},[_vm._v(" 普通用户 ")]),_c('a-select-option',{attrs:{"value":"ADMIN"}},[_vm._v(" 管理型用户 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }