<template>
  <a-form :form="form">
    <a-form-item label="用户名" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        placeholder="请输入用户名"
        v-decorator="[
          'name',
          { rules: [{ required: true, message: '请输入用户名!' }] },
        ]"
      />
    </a-form-item>
    <a-form-item label="密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input-password
        placeholder="请输入密码"
        v-decorator="[
          'password',
          { rules: [{ required: true, message: '请输入密码!' }] },
        ]"
      />
    </a-form-item>
    <a-form-item
      label="请确认密码"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol"
    >
      <a-input-password
        placeholder="请再次输入密码"
        v-decorator="[
          'passwordConfirm',
          { rules: [{ required: true, message: '请再次输入密码!' }] },
        ]"
      />
    </a-form-item>
  </a-form>
</template>

<script>
import { signUp } from "@/api/user";

export default {
  name: "CreateUser",
  components: {},
  props: {},
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      form: this.$form.createForm(this),
    };
  },
  methods: {
    onOk() {
      return new Promise((resolve) => {
        // eslint-disable-next-line
        // debugger;
        this.form.validateFields((err, values) => {
          if (!err) {
            if (values.password !== values.passwordConfirm) {
              this.$message.error("两次输入密码一致");
              return;
            }
            if (!/^\w{6,30}/.test(values.password)) {
              this.$message.error("密码不符合要求");
              return;
            }
            let formData = {
              name: values.name,
              password: values.password,
            };
            signUp(formData).then(() => {
              this.$message.success("创建新用户成功");
              resolve(true);
            });
          }
        });
      });
    },
    onCancel() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
  },
};
</script>

<style scoped>
</style>