<template>
  <a-form :form="form">
    <a-form-item label="用户名" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        placeholder="请输入用户名"
        v-decorator="[
          'name',
          { initialValue: userInfo.name },
          { rules: [{ required: true, message: '请输入用户名!' }] },
        ]"
      />
    </a-form-item>
    <a-form-item label="密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-button @click="reset">重置密码</a-button>
    </a-form-item>
    <a-form-item label="姓名" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        placeholder="请输入真实姓名"
        v-decorator="[
          'realName',
          { initialValue: userInfo.realName },
          { rules: [{ required: true, message: '请输入真实姓名!' }] },
        ]"
      />
    </a-form-item>
    <a-form-item label="所在部门" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        placeholder="请输入所在部门"
        v-decorator="[
          'department',
          { initialValue: userInfo.department },
          { rules: [{ required: true, message: '请输入所在部门!' }] },
        ]"
      />
    </a-form-item>
    <a-form-item label="职位" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        placeholder="请输入职位"
        v-decorator="[
          'position',
          { initialValue: userInfo.title },
          { rules: [{ required: true, message: '请输入职位!' }] },
        ]"
      />
    </a-form-item>
    <a-form-item label="联系电话" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        placeholder="请输入联系电话"
        v-decorator="[
          'phone',
          { initialValue: userInfo.phone },
          {
            rules: [
              {
                required: true,
                message: '请输入联系电话!',
              },
            ],
          },
        ]"
      />
    </a-form-item>
    <a-form-item label="电子邮件" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        placeholder="请输入电子邮件"
        v-decorator="[
          'email',
          { initialValue: userInfo.email },
          {
            rules: [
              {
                required: true,
                message: '请输入电子邮件!',
              },
            ],
          },
        ]"
      />
    </a-form-item>
    <a-form-item label="属性" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-select
        v-decorator="['userType', { initialValue: userInfo.userType }]"
        style="width: 120px"
      >
        <a-select-option value="GENERAL"> 普通用户 </a-select-option>
        <a-select-option value="ADMIN"> 管理型用户 </a-select-option>
      </a-select>
    </a-form-item>
  </a-form>
</template>

<script>
import { userInfo, resetUserPwd, updateUser } from "@/api/user";

export default {
  name: "InfoEdit",
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      form: this.$form.createForm(this),
      userInfo: {},
    };
  },
  created() {
    this.loadUserInfo();
  },
  methods: {
    async loadUserInfo() {
      const _data = await userInfo({ userId: this.record.userId });
      this.userInfo = _data.data;
    },
    reset() {
      resetUserPwd({ userId: this.record.userId }).then(() => {
        this.$message.success("重置成功");
      });
    },
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if (!err) {
            let formData = {
              avatar: "",
              department: values.department,
              email: values.email,
              name: values.name,
              phone: values.phone,
              realName: values.realName,
              title: values.position,
              userId: this.record.userId,
              userType: values.userType,
            };
            updateUser(formData).then(() => {
              this.$message.success("修改成功");
              resolve(true);
            });
          }
        });
      });
    },
    onCancel() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
  },
};
</script>
<style scoped>
.ant-dropdown-trigger {
  margin-left: 0 !important;
}
</style>
