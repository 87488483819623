<template>
  <div>
    <div class="item-box">
      <div>设置用户所在机构：</div>
      <a-select
        mode="multiple"
        :value="selectedOrgs"
        :labelInValue="true"
        @select="handleSelect"
        @deselect="(value) => handleDelete(value)"
        style="width: 100%"
      >
        <a-select-option v-for="org in orgList" :value="org.id" :key="org.id">
          {{ org.name }}
        </a-select-option>
      </a-select>
    </div>
    <div v-if="!loading">
      <div v-for="item in selectedOrgs" :key="item.key" class="item-box">
        <div>设置{{ item.label }}用户组</div>
        <a-select
          mode="multiple"
          :value="groupsOption[item.key].selectedIds"
          @select="
            (value) =>
              handleGroupSelect(value, groupsOption[item.key].selectedIds)
          "
          @deselect="
            (value) =>
              handleGroupDelete(value, groupsOption[item.key].selectedIds)
          "
          style="width: 100%"
        >
          <a-select-option
            v-for="group in groupsOption[item.key].list"
            :value="group.id.toString()"
            :key="group.name"
          >
            {{ group.name }}
          </a-select-option>
        </a-select>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getUserOrgList,
  getUserGroupListByOrgId,
  updateUserRole,
} from "@/api/auth";
export default {
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      orgList: [],
      selectedOrgs: [],
      // 根据选中的机构生成的用户组选项
      groupsOption: {},
      loading: false,
      // 用于存储用户初始的用户组角色权限情况
      authRelate: {},
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.authRelate = {};
      this.orgList = [];
      this.selectedOrgs = [];
      this.record.authRelate.forEach((item) => {
        this.authRelate[item.org.id] = {};
        item.roleGroups.forEach((i) => {
          this.authRelate[item.org.id][i.group.id] = i.roles.map((r) => r.id);
        });
      });
      let selectedOrg = Object.keys(this.authRelate);
      let PromiseArr = [];
      getUserOrgList().then((res) => {
        this.orgList = res.data;
        this.orgList.forEach((item) => {
          if (selectedOrg.includes(item.id.toString())) {
            this.selectedOrgs.push({
              key: item.id,
              label: item.name,
            });
            this.loading = true;
            PromiseArr.push(
              getUserGroupListByOrgId({ orgId: item.id }).then((result) => {
                this.groupsOption[item.id] = {
                  list: result.data,
                  selectedIds: Object.keys(this.authRelate[item.id]),
                };
              })
            );
          }
        });
        Promise.all(PromiseArr).finally(() => {
          this.loading = false
        })
      });
    },
    handleSelect(value) {
      this.selectedOrgs.push(value);
      if (this.groupsOption[value.key] == null) {
        this.loading = true;
        getUserGroupListByOrgId({ orgId: value.key })
          .then((res) => {
            this.groupsOption[value.key] = {
              selectedIds: this.authRelate[value.key]
                ? Object.keys(this.authRelate[value.key])
                : [],
              list: res.data,
            };
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handleDelete(value) {
      let index = this.selectedOrgs.findIndex((item) => item.key == value.key);
      if (index > -1) {
        this.selectedOrgs.splice(index, 1);
      }
    },
    handleGroupSelect(value, groups) {
      groups.push(value);
    },
    handleGroupDelete(value, groups) {
      let index = groups.indexOf(value);
      if (index > -1) {
        groups.splice(index, 1);
      }
    },
    onOk() {
      return new Promise((resolve) => {
        let formData = {
          userId: this.record.userId,
          bind: [],
        };
        this.selectedOrgs.forEach((item) => {
          this.groupsOption[item.key].selectedIds.forEach((i) => {
            formData.bind.push({
              groupId: i,
              roleIds: this.authRelate[item.key]
                ? this.authRelate[item.key][i]
                : [],
            });
          });
        });
        updateUserRole(formData).then(() => {
          this.$message.success("更新成功");
          resolve(true);
        });
      });
    },
    onCancel() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
  },
};
</script>

<style scoped>
/* tile uploaded pictures */
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}

.item-box {
  margin-bottom: 14px;
}
</style>
