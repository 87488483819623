import { render, staticRenderFns } from "./CreateUser.vue?vue&type=template&id=158c6b69&scoped=true&"
import script from "./CreateUser.vue?vue&type=script&lang=js&"
export * from "./CreateUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "158c6b69",
  null
  
)

export default component.exports