<template>
  <a-card :bordered="false">
    <a-button
      type="primary"
      icon="plus"
      @click="handleAdd"
      v-if="hasAction('add-user')"
      >新建用户</a-button
    >
    <a-table
      ref="table"
      size="default"
      :row-key="(record) => record.userId"
      :columns="columns"
      :data-source="userList"
      :pagination="{ pageSize: 10 }"
      :loading="loading"
    >
      <div slot="action" slot-scope="record">
        <template>
          <a @click="editRole(record)" v-if="hasAction('change-user-role')"
            >修改角色</a
          >
          <a-divider
            type="vertical"
            v-if="
              hasAction('change-user-role') && ( hasAction('change-user-group') || hasAction('edit-username-and-password') )
            "
          />
          <a @click="editGroup(record)" v-if="hasAction('change-user-group')"
            >修改用户组</a
          >
          <a-divider
            type="vertical"
            v-if="
              hasAction('change-user-group') &&
              hasAction('edit-username-and-password')
            "
          />
          <a
            @click="editUser(record)"
            v-if="hasAction('edit-username-and-password')"
          >
            修改用户信息</a
          >
        </template>
      </div>
    </a-table>
  </a-card>
</template>

<script>
import { getAllUsers } from "@/api/auth";
import CreateUser from "../components/CreateUser";
import UserForm from "./components/UserForm";
import GroupForm from './components/GroupForm'
import InfoEdit from "../components/InfoEdit";

let columns = [
  {
    title: "#",
    dataIndex: "userId",
    width:50
  },
  {
    title: "用户名称",
    dataIndex: "userName",
    width:150
  },
  {
    title: "姓名",
    dataIndex: "realName",
    width:100
  },
  {
    title: "角色名称",
    dataIndex: "authRelate",
    customRender: (record) => {
      let res = []
      record.forEach(item => {
        item.roleGroups.forEach(i => {
          res.push(`${item.org.name} - ${i.group.name} ${i.roles.length > 0 ? '-' + i.roles.map(r=>r.name).join('、') : ''}`)
        })
      })
      return res.join('，')
    },
  },
];

export default {
  data() {
    return {
      columns: columns,
      userList: [],
      loading:false
    };
  },
  created() {
    if (
      (this.hasAction("change-user-role") ||
        this.hasAction("edit-username-and-password") ||
        this.hasAction("delete-user")) &&
      this.columns.length < 5
    ) {
      this.columns.push({
        title: "操作",
        scopedSlots: {
          customRender: "action",
        },
        width:300
      });
    }
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true
      getAllUsers().then((res) => {
        if (res.code === 0) {
          this.userList = res.data;
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleAdd() {
      this.$dialog(
        CreateUser,
        {
          on: {
            ok: () => {
              this.loadData();
            },
            cancel() {},
            close() {},
          },
        },
        {
          title: "新建用户",
          width: 500,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    editUser(record) {
      this.$dialog(
        InfoEdit,
        {
          record,
          on: {
            ok: () => {
              this.loadData();
            },
            cancel() {},
            close() {},
          },
        },
        {
          title: "修改用户信息",
          width: 500,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    editRole(record) {
      this.$dialog(
        UserForm,
        {
          record,
          on: {
            ok: () => {
              this.loadData();
            },
            cancel() {},
            close() {},
          },
        },
        {
          title: "修改角色",
          width: 500,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    editGroup(record) {
      this.$dialog(
        GroupForm,
        {
          record,
          on: {
            ok: () => {
              this.loadData();
            },
            cancel() {},
            close() {},
          },
        },
        {
          title: "修改用户组",
          width: 500,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
  },
};
</script>